import { useState, useEffect } from 'react'
import { Outlet, Routes, Route,Link,useNavigate } from 'react-router-dom'
import Cliente from "../bundles/Clientes/views/ClientesIndex";
import Tpdv from "../bundles/Tpdv/views/TpdvIndex";
import Ofertas from "../bundles/Clientes/views/OfertasIndex";
import { Bars4Icon, ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/24/outline";

function Layout(){      
  const navigate = useNavigate();
  const [m1, setm1] = useState(1);
  const [snt, setsnt] = useState('hidden');
  
  const handleChangx = () =>{    
    localStorage.setItem("@xlatitude23", JSON.stringify(-17.78629));    
    localStorage.setItem("@xlongitude23", JSON.stringify(-63.18117));       
    localStorage.setItem("@xciudad", JSON.stringify("Santa Cruz"));
    localStorage.setItem("@xmoneda", JSON.stringify({
      labelMoneda:"Boliviano (BO)",
      moneda:"BO"
    }));    
  }   
  
  useEffect(() => {
      handleChangx()
    return () => {    
    };
  }, []);

  const links = [{ id: 1, href: "#inicio", text: "Inicio"},
                 { id: 2, href: "#paquetes", text: "Paquetes"},
                 { id: 3, href: "#ofertas", text: "Ofertas laborales"},
                 { id: 4, href: "#preguntas", text: "Preguntas frecuentes"},
                 { id: 5, href: "#contacto", text: "Contacto"}
                ]

  const handleSnt = () =>{
    let nd = snt === 'hidden' ? 'visible':'hidden'
    setsnt(nd)
  }

 
            
  return(
    <div className="h-full w-full flex-col flex">

    <div className="h-auto w-full bg-[#015eea] flex shadow-md text-[10px]"> 
      <div className='h-14 lg:w-2/12 w-10/12 flex items-center pl-2'>
            <img
                alt="..."
                className="h-12 w-auto"
                src={require("./log.png")}
            />       
      </div>  

      <div className='lg:hidden h-14 w-2/12 flex flex-col justify-center items-center'>
          <button 
            className='h-10 w-10 border rounded bg-white z-0 items-center justify-center flex'
            onClick={() => handleSnt()}
            >
              <Bars4Icon className='h-7 w-7 text-gray-400'/>
          </button>          
          <div className={`${snt} h-40 w-56 border shadow-md bg-gray-100 absolute z-10 -ml-48 mt-52`}>
              
                <div className='h-max w-full flex-col list-none list-inside p-2'>
                  {links.map(link =>
                      <div 
                        key = {link.id}                         
                        className='h-6 w-full pl-2 pr-2 text-[14px] text-gray-500 font-bold hover:bg-blue-500 hover:text-white'>
                         <a 
                          href={link.href}
                          onClick={()=>setsnt('hidden')}
                        >
                          {link.text}                        
                        </a>
                      </div>)}              
                </div>                       
          </div>
      </div>  


      <div className='h-14 lg:w-8/12 lg:flex hidden items-center pl-2'>
                <div className='h-10 w-full flex items-center'>
                  {links.map(link =>
                      <div 
                        key = {link.id}                                                 
                        className='h-10 flex uppercase items-center pl-3 pr-3 text-[14px] text-gray-50 font-bold hover:bg-blue-500 hover:text-white'>                                                  
                        <a href={link.href}>
                        {link.text}                        
                        </a>
                      </div>)}              
                </div> 
      </div> 
      <div className='h-14 lg:w-2/12 lg:flex hidden items-center justify-center'>
          <button          
          className='h-10 w-40 border uppercase bg-gray-100 rounded shadow-md flex items-center justify-center text-[12px] font-bold text-gray-500'>
            <a 
              href="https://1click-bo.com/app" target="_blank" rel="noreferrer">
              Ingresar
            </a>
          </button>
      </div> 
         
    </div>   

    <div id="inicio"  className="h-700 w-full bg-slate-700 p-2 "> 
      <div className='h-max w-full flex'>                        
            <Outlet/>  
            <Routes>                 
              <Route path="/" element={<Cliente />}/>                                                    
              <Route path="/tpdv" element={<Tpdv />}/>                                                                                                                        
            </Routes>       
      </div>                                
    </div> 

    <div  id="ofertas" className="h-max w-full p-1 bg-blue-700 flex-col">   
      <h6 className='h-10 mt-2 mb-4 flex items-center pl-4 text-[16px] text-gray-200 font-bold '><span>Ofertas Laborales</span></h6>
      <div>
        <Ofertas/>
      </div>              
    </div>  

    <div  id="paquetes" className="h-max w-full p-1 flex-col bg-slate-700">   
      <h6 className='h-8 flex items-center pl-4 text-[16px] text-gray-50 font-bold '><span>Paquetes para tu empresa</span></h6>
      
      <div className='lg:hidden h-max w-full flex flex-col justify-center p-1'>
                
              <div className='rounded w-full flex shadow-md bg-slate-600 border border-gray-400'>
                <div className='h-32 w-5/12 bg-[#015eea] rounded-l flex justify-center items-center border-r border-gray-400'>
                  <span className='text-[12px] font-bold text-gray-50'>Vip</span>
                </div>
                <div className='h-32 w-7/12 flex justify-center flex-col text-[12px] text-gray-300 font-bold'>
                  <p className='h-8 pl-2 border-b border-gray-400 items-center flex'>10 Sucursales</p>
                  <p className='h-8 pl-2 border-b border-gray-400 items-center flex'>Ubicación</p>
                  <p className='h-8 pl-2 border-b border-gray-400 items-center flex'>Datos empresa</p>
                  <p className='h-8 pl-2 border-b border-gray-400 items-center flex'>Links redes sociales</p>
                                   
                </div>
              </div>

              <div className='rounded w-full flex shadow-md bg-slate-600 border border-gray-400'>
                <div className='h-32 w-5/12 bg-[#015eea] rounded-l flex justify-center items-center border-r border-gray-400'>
                  <span className='text-[12px] font-bold text-gray-50'>Diamante</span>
                </div>
                <div className='h-32 w-7/12 flex justify-center flex-col text-[12px] text-gray-300 font-bold'>
                  <p className='h-8 pl-2 border-b border-gray-400 items-center flex'>30 Sucursales</p>
                  <p className='h-8 pl-2 border-b border-gray-400 items-center flex'>Ubicación</p>
                  <p className='h-8 pl-2 border-b border-gray-400 items-center flex'>Datos empresa</p>
                  <p className='h-8 pl-2 border-b border-gray-400 items-center flex'>Links redes sociales</p>
                                       
                </div>
              </div>

              <div className='rounded w-full flex shadow-md bg-slate-600 border border-gray-400'>
                <div className='h-32 w-5/12 bg-[#015eea] rounded-l flex justify-center items-center border-r border-gray-400'>
                  <span className='text-[12px] font-bold text-gray-50'>Premium</span>
                </div>
                <div className='h-32 w-7/12 flex justify-center flex-col text-[12px] text-gray-300 font-bold'>
                  <p className='h-8 pl-2 border-b border-gray-400 items-center flex'>Sin límite de sucursales</p>
                  <p className='h-8 pl-2 border-b border-gray-400 items-center flex'>Ubicación</p>
                  <p className='h-8 pl-2 border-b border-gray-400 items-center flex'>Datos empresa</p>
                  <p className='h-8 pl-2 border-b border-gray-400 items-center flex'>Links redes sociales</p>
                  <p className='h-8 pl-2 items-center flex'>1 video de publicidad</p>                        
                </div>
              </div>    
      </div>

      <div className='hidden lg:visible h-max w-full lg:flex justify-around p-1'>

               

              <div className='rounded w-1/5 flex-col shadow-md bg-slate-600 border border-gray-400'>
                <div className='h-14 w-full bg-[#015eea] rounded-l flex justify-center items-center'>
                  <span className='text-[12px] font-bold text-gray-50'>Vip</span>
                </div>
                <div className='h-48 w-full flex justify-center flex-col text-[12px] text-gray-300 font-bold'>
                  <p className='h-8 pl-2 border-b border-gray-400 items-center flex'>10 Sucursales</p>
                  <p className='h-8 pl-2 border-b border-gray-400 items-center flex'>Ubicación</p>
                  <p className='h-8 pl-2 border-b border-gray-400 items-center flex'>Datos empresa</p>
                  <p className='h-8 pl-2 border-b border-gray-400 items-center flex'>Links redes sociales</p>
                                       
                </div>
              </div>

              <div className='rounded w-1/5 flex-col shadow-md bg-slate-600 border border-gray-400'>
                <div className='h-14 w-full bg-[#015eea] rounded-l flex justify-center items-center'>
                  <span className='text-[12px] font-bold text-gray-50'>Diamante</span>
                </div>
                <div className='h-48 w-full flex justify-center flex-col text-[12px] text-gray-300 font-bold'>
                  <p className='h-8 pl-2 border-b border-gray-400 items-center flex'>30 Sucursales</p>
                  <p className='h-8 pl-2 border-b border-gray-400 items-center flex'>Ubicación</p>
                  <p className='h-8 pl-2 border-b border-gray-400 items-center flex'>Datos empresa</p>
                  <p className='h-8 pl-2 border-b border-gray-400 items-center flex'>Links redes sociales</p>
                                      
                </div>
              </div>

              <div className='rounded w-1/5 flex-col shadow-md bg-slate-600 border border-gray-400'>
                <div className='h-14 w-full bg-[#015eea] rounded-l flex justify-center items-center'>
                  <span className='text-[12px] font-bold text-gray-50'>Premium</span>
                </div>
                <div className='h-48 w-full flex justify-center flex-col text-[12px] text-gray-300 font-bold'>
                  <p className='h-8 pl-2 border-b border-gray-400 items-center flex'>Sin límite de sucursales</p>
                  <p className='h-8 pl-2 border-b border-gray-400 items-center flex'>Ubicación</p>
                  <p className='h-8 pl-2 border-b border-gray-400 items-center flex'>Datos empresa</p>
                  <p className='h-8 pl-2 border-b border-gray-400 items-center flex'>Links redes sociales</p>
                  <p className='h-8 pl-2 items-center flex'>1 video de publicidad</p>                        
                </div>
              </div>    
      </div>
    </div>     


    <div id="preguntas" className="h-max w-full flex-col p-2 bg-cover bg-gray-100">      
          <div className='h-full flex w-full bg-white flex-col text-[14px] text-gray-600 border border-gray-200'>
              <h5 className='h-10 mb-2 pl-4 pt-2 text-[16px] font-bold'>Preguntas frecuentes</h5>
              <div className={m1 === 1 ? 'h-max border border-gray-300 bg-gradient-to-t from-gray-200 to-gray-50':'h-max border border-gray-200 bg-gradient-to-t from-gray-200 to-gray-50 opacity-60'}>
                <button 
                onClick={() => setm1(1)}
                className='h-10 w-full rounded flex items-center justify-center'>
                  <div className='h-9 w-full flex items-center pl-2'>
                    <span className='text-[12px] font-bold'>Quienes Somos</span> 
                  </div>
                  <div className='h-9 w-10 flex justify-center items-center'>
                  {m1 === 1 ? <ChevronDownIcon className="h-5 w-5 text-gray-400 " /> : <ChevronRightIcon className="h-5 w-5 text-gray-400" />}
                  </div>
                </button>                
              </div>              
              <div className={m1 === 1 ? 'h-max w-full pl-2 pr-2 mb-4': 'hidden' }>
                <div className='h-max borde shadow-md w-full items-center p-5 rounded-b'> 
                    <span>1Click es una plataforma digital formado por un equipo audaz, creativo, responsable y dinámico, comprometido en crear conexión cliente/empresa de una manera totalmente fácil y directa. </span>     
                </div>                    
              </div>  

              <div className={m1 === 2 ? 'h-max border border-gray-300 bg-gradient-to-t from-gray-200 to-gray-50':'h-max border border-gray-200 bg-gradient-to-t from-gray-200 to-gray-50 opacity-60'}>
                <button 
                onClick={() => setm1(2)}
                className='h-10 w-full rounded flex items-center justify-center'>
                  <div className='h-9 w-full flex items-center pl-2'>
                    <span className='text-[12px] font-bold'>Que puede ofrecerme 1click ? </span> 
                  </div>
                  <div className='h-9 w-10 flex justify-center items-center'>
                  {m1 === 2 ? <ChevronDownIcon className="h-5 w-5 text-gray-400 " /> : <ChevronRightIcon className="h-5 w-5 text-gray-400" />}
                  </div>
                </button>                
              </div>              
              <div className={m1 === 2 ? 'h-max w-full pl-2 pr-2 mb-4': 'hidden' }>
                <div className='h-max borde shadow-md w-full items-center p-5 rounded-b'> 
                    <span>Te ofrecemos un servicio directo en donde tu cliente obtiene información precisa sobre tu negocio de manera dinámica, actualizada, rápida y centralizada, brindando una experiencia online en la búsqueda de información de tu negocio.  </span>     
                </div>                    
              </div>   

              <div className={m1 === 3 ? 'h-max border border-gray-300 bg-gradient-to-t from-gray-200 to-gray-50':'h-max border border-gray-200 bg-gradient-to-t from-gray-200 to-gray-50 opacity-60'}>
                <button 
                onClick={() => setm1(3)}
                className='h-10 w-full rounded flex items-center justify-center'>
                  <div className='h-9 w-full flex items-center pl-2'>
                    <span className='text-[12px] font-bold'>Necesito este servicio ?  </span> 
                  </div>
                  <div className='h-9 w-10 flex justify-center items-center'>
                  {m1 === 3 ? <ChevronDownIcon className="h-5 w-5 text-gray-400 " /> : <ChevronRightIcon className="h-5 w-5 text-gray-400" />}
                  </div>
                </button>                
              </div>              
              <div className={m1 === 3 ? 'h-max w-full pl-2 pr-2 mb-4': 'hidden' }>
                <div className='h-max borde shadow-md w-full items-center p-5 rounded-b'> 
                    <span>Las conexiones mediante internet se ha incrementado de manera exponencial, el mundo digital ocupa el primer lugar ya que todos tenemos fácil acceso a la información desde nuestro celular o cualquier dispositivo. Por lo tanto nuestro servicio es una opción alterna e innovadora de compartir tu información empresarial de manera confiable a tus clientes. </span>     
                </div>                    
              </div> 

              <div className={m1 === 4 ? 'h-max border border-gray-300 bg-gradient-to-t from-gray-200 to-gray-50':'h-max border border-gray-200 bg-gradient-to-t from-gray-200 to-gray-50 opacity-60'}>
                <button 
                onClick={() => setm1(4)}
                className='h-10 w-full rounded flex items-center justify-center'>
                  <div className='h-9 w-full flex items-center pl-2'>
                    <span className='text-[12px] font-bold'>Que información puedo compartir con mis clientes y como beneficia esta App a mi negocio o empresa ?   </span> 
                  </div>
                  <div className='h-9 w-10 flex justify-center items-center'>
                  {m1 === 4 ? <ChevronDownIcon className="h-5 w-5 text-gray-400 " /> : <ChevronRightIcon className="h-5 w-5 text-gray-400" />}
                  </div>
                </button>                
              </div>              
              <div className={m1 === 4 ? 'h-max w-full pl-2 pr-2 mb-4': 'hidden' }>
                <div className='h-max borde shadow-md w-full p-5 rounded-b flex-col flex'> 
                    <span>La App 1click te permite compartir con tus clientes los siguientes datos:</span>     
                    <span>- El horario de atención de tu negocio.</span>     
                    <span>- Enlaces a tus redes sociales.</span>     
                    <span>- Ubicación en el mapa.</span>     
                    <span>- Dirección descriptiva.</span>     
                    <span>- Números telefónicos o WhatsApp de atención al cliente. </span>     
                    <span>- Promocionar tus ofertas.</span>     
                    <span>- Publicidad. Toda esta vital información en una sola App, generando beneficios significativos para tu empresa y tus clientes, dando un plus extra en ambas partes (cliente/empresa) simplificando tiempo en la búsqueda de tu negocio, brindando información precisa y confiable a todos tus clientes.</span>                         
                </div>                    
              </div> 


              <div className={m1 === 5 ? 'h-max border border-gray-300 bg-gradient-to-t from-gray-200 to-gray-50':'h-max border border-gray-200 bg-gradient-to-t from-gray-200 to-gray-50 opacity-60'}>
                <button 
                onClick={() => setm1(5)}
                className='h-10 w-full rounded flex items-center justify-center'>
                  <div className='h-9 w-full flex items-center pl-2'>
                    <span className='text-[12px] font-bold'>Donde puedo descargar la App ?   </span> 
                  </div>
                  <div className='h-9 w-10 flex justify-center items-center'>
                  {m1 === 5 ? <ChevronDownIcon className="h-5 w-5 text-gray-400 " /> : <ChevronRightIcon className="h-5 w-5 text-gray-400" />}
                  </div>
                </button>                
              </div>              
              <div className={m1 === 5 ? 'h-max w-full pl-2 pr-2 mb-4': 'hidden' }>
                <div className='h-max borde shadow-md w-full items-center p-5 rounded-b'> 
                    <span>La App esta disponible tanto para Android y iOS en sus respectivas tiendas digitales. </span>     
                </div>                    
              </div> 

              <div className={m1 === 6 ? 'h-max border border-gray-300 bg-gradient-to-t from-gray-200 to-gray-50':'h-max border border-gray-200 bg-gradient-to-t from-gray-200 to-gray-50 opacity-60'}>
                <button 
                onClick={() => setm1(6)}
                className='h-10 w-full rounded flex items-center justify-center'>
                  <div className='h-9 w-full flex items-center pl-2'>
                    <span className='text-[12px] font-bold'>Es fácil el registro y suscripción en 1click?    </span> 
                  </div>
                  <div className='h-9 w-10 flex justify-center items-center'>
                  {m1 === 6 ? <ChevronDownIcon className="h-5 w-5 text-gray-400 " /> : <ChevronRightIcon className="h-5 w-5 text-gray-400" />}
                  </div>
                </button>                
              </div>              
              <div className={m1 === 6 ? 'h-max w-full pl-2 pr-2 mb-4': 'hidden' }>
                <div className='h-max borde shadow-md w-full items-center p-5 rounded-b'> 
                    <span>Sí, está diseñado para el fácil manejo , además contamos con soporte técnico para así facilitar el registro en nuestra plataforma digital, si es gusto del cliente nosotros también podemos realizar el registro de manera rápida por usted.  </span>     
                </div>                    
              </div> 

          </div> 
    </div>     

    <div  className="h-max w-full p-1 bg-slate-700">                     
      <div className='h-20 w-full flex items-center justify-around'>
              <Link 
              to="https://play.google.com/store/apps/details?id=com.beggu.oneclik&pcampaignid=web_share"               
              target="_blank" 
              className='full border rounded-md p-1 flex items-center justify-center bg-white'>
                <img
                        alt="..."
                        className="h-10 w-auto"
                        src={require("./images.png")}
                    />    
              </Link>
              
              <Link 
              to="https://apps.apple.com/pe/app/1click/id1591412521"
              target="_blank"
              className='full border rounded-md p-1 flex items-center justify-center bg-black'>
                    <img
                        alt="..."
                        className="h-10 w-auto"
                        src={require("./store.png")}
                    />    
              </Link>        
      </div>            
    </div>

    <div id="contacto" className="h-200 w-full flex-col bg-gray-800 p-4">  
            <div className='h-2/6  flex items-center pl-2'>
              <img
                    alt="..."
                    className="h-12 w-auto"
                    src={require("./log.png")}
                /> 
            </div>
            <div className='h-3/6 flex-col pl-2 flex justify-center text-[14px] text-gray-300'>
              <p className='h-7'>Zona Norte - Santa Cruz Bolivia</p>
              <p className='h-7'>Telf: +591 - 33215060</p>
              <p className='h-7'>Celular: +591 - 77053060</p>
              <p className='h-7'>Email: comercial@1click-bo.com</p>
            </div>             
    </div>    
    <div id="dos" className="h-14 flex bg-[#015eea] w-full items-center justify-center text-[12px]">   
          <span className='text-gray-200' >Copyright ©2024 All rights reserved | by Beggu </span>
    </div>  

  </div> 
    )
}
export default Layout;

